<template>
  <v-container fluid class="pt-0">
    <v-layout>
      <v-row>
        <v-col cols="12">
          <v-data-table
            ref="tableMovimientos"
            fixed-header
            :headers="headers"
            :items="movimientos"
            :loading="isLoading"
            loading-text="Cargando... Espera un Momento"
            no-data-text="No hay datos para mostrar"
            :search="buscarTabla"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div>
                  <v-toolbar-title>Movimientos</v-toolbar-title>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon color="success" class="mx-2" @click="actualizarTabla">
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </v-toolbar>
              <v-layout class="py-3 d-flex">
                <v-row>
                  <v-col cols="12" sm="12" md="8">
                    <v-text-field
                      v-model="buscarTabla"
                      prepend-inner-icon="mdi-magnify"
                      label="Buscar en la tabla..."
                      class="mx-4 flex-shrink-0 flex-grow-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4">
                    <v-dialog
                      ref="dialogFechaTabla"
                      v-model="modalFechaTabla"
                      :return-value.sync="fechaTabla"
                      width="290px"
                      class="flex-shrink-0 flex-grow-0"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="computedFechaFormateada"
                          label="Fecha de Selección"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-on="on"
                          class="mx-4"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fechaTabla" scrollable locale="es">
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modalFechaTabla = false">Cancelar</v-btn>
                        <v-btn text color="primary" @click="actualizarFecha()">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" sm="12" class="d-flex justify-end">
                    <v-btn
                      outlined
                      :dark="!!movimientos.length"
                      :disabled="!movimientos.length"
                      class="mx-4"
                      color="green"
                      @click="exportToExcel"
                    >
                      <v-icon left>mdi-file-excel</v-icon>Exportar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-layout>
            </template>
            <template
              v-slot:item.fechaMovimiento="{ item }"
            >{{ formatDateTable(item.fechaMovimiento) }}</template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import XLSX from 'xlsx'

export default {
  data: () => ({
    verificado: true,
    headers: [
      { text: '#', value: 'idMovimiento', align: 'center' },
      {
        text: 'Establecimiento',
        value: 'nombreEstablecimiento',
        align: 'center'
      },
      {
        text: 'Producto',
        value: 'nombreProducto',
        align: 'center'
      },
      {
        text: 'Trabajador',
        value: 'nombreTrabajador',
        align: 'center'
      },
      {
        text: 'Tipo de Movimiento',
        value: 'tipoMovimiento',
        align: 'center'
      },
      { text: 'Cantidad', value: 'cantidad', align: 'center' },
      {
        text: 'Fecha de Movimiento',
        value: 'fechaMovimiento',
        align: 'center'
      },
      {
        text: 'Detalle de Movimiento',
        value: 'detalle',
        align: 'left',
        width: '25%'
      }
    ],
    movimientos: [],
    fechaTabla: new Date(new Date().setHours(-5)).toISOString().substr(0, 10),
    modalFechaTabla: false,
    buscarTabla: '',
    infoSnackbar: false,
    infoSnackbarMessage: ''
  }),
  methods: {
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    formatDateTable(date) {
      let parsedDate = new Date(parseInt(date))
      let options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }
      return `${parsedDate.toLocaleDateString(
        'es-PE',
        options
      )} ${parsedDate.toLocaleTimeString()}`
    },

    cleanSource(source) {
      return JSON.parse(JSON.stringify(source))
    },

    async actualizarTabla() {
      let horaCeroInicio = new Date(this.fechaTabla).setHours(24)
      let horaCeroFin = new Date(this.fechaTabla).setHours(48)
      //this.setError({ isError: false, error: "" });
      let fechas = { horaCeroInicio, horaCeroFin }
      this.movimientos = await this.fetchMovimientos(fechas)
      //console.log(this.ventas)
    },

    actualizarFecha() {
      this.$refs.dialogFechaTabla.save(this.fechaTabla)
      this.actualizarTabla()
    },

    exportToExcel() {
      let resource = this.cleanSource(this.movimientos)

      for (let i = 0; i < resource.length; i++) {
        resource[i].fechaMovimiento = this.formatDateTable(
          resource[i].fechaMovimiento
        )
      }

      let dataExported = XLSX.utils.json_to_sheet(resource)
      dataExported['A1'].v = 'ID de Movimiento'
      dataExported['B1'].v = 'Establecimiento'
      dataExported['C1'].v = 'Producto'
      dataExported['D1'].v = 'Trabajador'
      dataExported['E1'].v = 'Tipo de Movimiento'
      dataExported['F1'].v = 'Cantidad'
      dataExported['G1'].v = 'Fecha de Movimiento'
      dataExported['H1'].v = 'Detalle'

      const workbook = XLSX.utils.book_new()
      const filename = `${new Date().getTime()}-MovimientosAdmin`
      XLSX.utils.book_append_sheet(workbook, dataExported, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },

    ...mapActions('user', ['fetchMovimientos']),

    ...mapMutations('user', ['setError', 'loadingRequest'])
  },
  computed: {
    computedFechaFormateada() {
      return this.formatDate(this.fechaTabla)
    },

    getFechaTabla() {
      const offsetDate = new Date(this.fechaTabla).setHours(
        new Date(this.fechaTabla).getHours() - 5
      )
      return new Date(offsetDate).toISOString().substr(0, 10)
    },

    getFechaFormateada() {
      return new Date(this.fechaTabla).toISOString().substr(0, 10)
    },

    getErrorMessage() {
      switch (this.getError.errorMessage) {
        case 'Network Error':
          return 'Error al intentar conectarse al servidor, contacte al administrador'
        default:
          return this.getError.errorMessage
      }
    },

    ...mapGetters('user', ['isError', 'getError', 'isLoading'])
  },

  mounted: async function() {
    this.actualizarTabla()
  }
}
</script>

<style>
</style>